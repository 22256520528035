import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ReviewService } from '@/services/review.service';

import { IInstagramReview, IYandexReviewNew } from '@/types/new/reviews';

export const reviewsApi = createApi({
  reducerPath: 'reviewsApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['YandexReviewsTag', 'ReviewsTag'],
  endpoints: (build) => ({
    getInstagramReviews: build.query<IInstagramReview[], { ids?: number[]; limit?: number }>({
      providesTags: ['ReviewsTag'],
      queryFn: async ({ ids, limit }) => {
        const { data: reviews } = await ReviewService.getInstagramReviews(ids, 0, limit);

        return { data: reviews };
      },
    }),
    getYandexReviews: build.query<IYandexReviewNew[], { limit?: number }>({
      providesTags: ['YandexReviewsTag'],
      queryFn: async ({ limit }) => {
        const { data } = await ReviewService.getYandexReviews(0, limit);

        return { data: data.items };
      },
    }),
  }),
});

export const { useGetInstagramReviewsQuery, useGetYandexReviewsQuery } = reviewsApi;
