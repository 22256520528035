import { createSlice } from '@reduxjs/toolkit';

interface MobileApplicationQrModalInitial {
  modalIsOpen: boolean;
}

const initialState: MobileApplicationQrModalInitial = {
  modalIsOpen: false,
};

export const mobilleApplicationQrModal = createSlice({
  name: 'mobilleApplicationQrModal',
  initialState,
  reducers: {
    toggleModal(state) {
      if (state.modalIsOpen) {
        window.document.body.classList.remove('no-scroll');
      } else {
        window.document.body.classList.add('no-scroll');
      }
      state.modalIsOpen = !state.modalIsOpen;
    },
  },
});

export const { toggleModal } = mobilleApplicationQrModal.actions;
export default mobilleApplicationQrModal;
