import { newBackendHttp } from '@/api/instances/rest';

import { IUniversalResponse } from '@/types/new/backend-responses';
import { IHeaderBanner } from '@/types/new/header-banners';

export class HeaderBannersService {
  static async getAllBanners(): Promise<
    IUniversalResponse<{ items: IHeaderBanner[]; total: number }>
  > {
    const { data } = await newBackendHttp.get<
      IUniversalResponse<{ items: IHeaderBanner[]; total: number }>
    >('/banners');

    return data;
  }
}
