import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isOpen: boolean;
}

const initialState: InitialState = {
  isOpen: false,
};

export const drawer = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggleDrawer(state) {
      state.isOpen = !state.isOpen;
    },
    closeDrawer(state) {
      state.isOpen = false;
    },
  },
});

export const { toggleDrawer, closeDrawer } = drawer.actions;
export default drawer;
