import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IProductNew } from '@/types/new/products';

import { LS_FAVORITES } from '@/constants/local-storage.constant';

interface IFavoritesProductsState {
  products: IProductNew[];
}

const initialState: IFavoritesProductsState = {
  products: [],
};

export const favoriteProductsSlice = createSlice({
  name: 'favoriteProducts',
  initialState,
  reducers: {
    addToFavorites(state, action: PayloadAction<IProductNew>) {
      state.products.push(action.payload);
    },

    removeFromFavorites(state, action: PayloadAction<{ id: number }>) {
      state.products = state.products.filter((el) => el.id !== action.payload.id);
    },

    removeAllFromFavorites(state) {
      state.products = [];
    },

    getFavoritesProducts(state) {
      state.products = JSON.parse(localStorage.getItem(LS_FAVORITES) ?? '[]');
    },
    setFavoritesProducts(state, action: PayloadAction<IProductNew[]>) {
      state.products = action.payload;
    },
  },
});

export const {
  addToFavorites,
  removeFromFavorites,
  getFavoritesProducts,
  removeAllFromFavorites,
  setFavoritesProducts,
} = favoriteProductsSlice.actions;
export default favoriteProductsSlice;
