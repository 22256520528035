import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { CitiesService } from '@/services/cities.service';

import { ICityNew } from '@/types/new/cities';

export const citiesApi = createApi({
  reducerPath: 'citiesApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['CitiesTag'],
  endpoints: (build) => ({
    getCities: build.query<ICityNew[], void>({
      providesTags: ['CitiesTag'],
      queryFn: async () => {
        const { data: cities } = await CitiesService.getAllCities();
        return { data: cities?.items };
      },
    }),
  }),
});

export const { useGetCitiesQuery } = citiesApi;
