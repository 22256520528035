import { createAsyncThunk } from '@reduxjs/toolkit';

import { ProductService } from '@/services/product.service';

export const fetchAdditionalProducts = createAsyncThunk(
  'checkoutProducts/fetchAdditionalProducts',
  async () => {
    const { data } = await ProductService.getAdditionalProducts();
    return data;
  }
);
