import { newBackendHttp } from '@/api/instances/rest';

import { IUniversalResponse } from '@/types/new/backend-responses';
import { ICityNew } from '@/types/new/cities';

type IGetAllCities = IUniversalResponse<{
  total: number;
  items: ICityNew[];
}>;
export class CitiesService {
  static async getAllCities(): Promise<IGetAllCities> {
    const { data } = await newBackendHttp.get<IGetAllCities>('/cities?skip=0&take=99');

    return data;
  }
}
