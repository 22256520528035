import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { OrderService } from '@/services/order.service';

import { IOrderStatusResponse } from '@/types/new/orders';

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['OrdersTag'],
  endpoints: (build) => ({
    getOrderStatus: build.query<IOrderStatusResponse, { uid: string }>({
      providesTags: ['OrdersTag'],
      queryFn: async ({ uid }) => {
        const data = await OrderService.getOrderStatus(uid);
        return { data: data.data };
      },
    }),
  }),
});

export const { useGetOrderStatusQuery } = ordersApi;
