import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OpenStatesType = 'all' | 'price' | 'color' | 'flower' | 'country' | 'category' | 'none';

export type OptionType =
  | {
      name: 'По умолчанию';
      value: '';
    }
  | {
      name: 'По популярности';
      value: 'rank';
    }
  | {
      name: 'Сначала дешёвые';
      value: 'price:asc';
    }
  | {
      name: 'Сначала дорогие';
      value: 'price:desc';
    };

type initialParams = {
  openState: string[];
  prices: number[];
  selectedColors: number[];
  selectedFlowers: number[];
  selectedReasons: number[];
  selectedSort: OptionType;
};

const initialState: initialParams = {
  openState: ['none'],
  prices: [1, 20000],
  selectedColors: [],
  selectedFlowers: [],
  selectedReasons: [],
  selectedSort: { name: 'По умолчанию', value: '' },
};

export const sortingProductsSlice = createSlice({
  name: 'sortingProducts',
  initialState,
  reducers: {
    setPrices(state, action: PayloadAction<number[]>) {
      state.prices = action.payload;
    },

    setOpenState(state, action: PayloadAction<string>) {
      if (action.payload === 'all') {
        state.openState = ['price', 'color', 'flower', 'category', 'country'];
        return;
      }
      if (action.payload === 'none') {
        state.openState = [action.payload];
        return;
      } else {
        state.openState = state.openState.filter((value) => value !== 'none');
      }
      if (state.openState.includes(action.payload)) {
        state.openState = state.openState.filter((value) => value !== action.payload);
      } else {
        state.openState.push(action.payload);
      }
    },
  },
});

export const { setPrices, setOpenState } = sortingProductsSlice.actions;
export default sortingProductsSlice;
