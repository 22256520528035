import qs from 'qs';

import { newBackendHttp } from '@/api/instances/rest';

import { IUniversalResponse } from '@/types/new/backend-responses';
import {
  IForbiddenOrderDay,
  IOrderExtended,
  IOrderNew,
  IOrderStatusResponse,
} from '@/types/new/orders';

type TOrderCreate = IUniversalResponse<{
  paymentId: string;
  paymentURL: string;
  redirectUrl: string;
}>;
export class OrderService {
  static async create(order: IOrderNew): Promise<TOrderCreate> {
    const { data } = await newBackendHttp.post<TOrderCreate>('/orders/create', order);

    return data;
  }

  static async getById(
    orderId: string,
    accessToken?: string
  ): Promise<IUniversalResponse<IOrderExtended>> {
    const { data } = await newBackendHttp.get<IUniversalResponse<IOrderExtended>>(
      `/orders/${orderId}`,
      {
        ...(accessToken
          ? {
              headers: {
                Cookie: `accessToken=${accessToken}`,
              },
            }
          : {}),
      }
    );

    return data;
  }

  static async getByUID(
    orderUID: string,
    accessToken?: string
  ): Promise<IUniversalResponse<IOrderExtended>> {
    const { data } = await newBackendHttp.get<IUniversalResponse<IOrderExtended>>(
      `/orders/by-uid/${orderUID}`,
      {
        ...(accessToken
          ? {
              headers: {
                Cookie: `accessToken=${accessToken}`,
              },
            }
          : {}),
      }
    );

    return data;
  }

  static async sendIndividualOrder(
    body: { wishes: string; phone: string; image: string },
    accessToken?: string
  ) {
    await newBackendHttp.post('/orders/individual', body, {
      ...(accessToken
        ? {
            headers: {
              Cookie: `accessToken=${accessToken}`,
            },
          }
        : {}),
    });
  }

  static async getForbiddenDatesForOrder(
    start_date: string,
    end_date: string
  ): Promise<IUniversalResponse<IForbiddenOrderDay[]>> {
    const query = qs.stringify({ start_date, end_date });

    const { data } = await newBackendHttp.get<IUniversalResponse<IForbiddenOrderDay[]>>(
      `/orders/limit/forbidden-dates?${query}`
    );

    return data;
  }

  static async getOrderStatus(uid: string): Promise<IUniversalResponse<IOrderStatusResponse>> {
    const { data } = await newBackendHttp.get<IUniversalResponse<IOrderStatusResponse>>(
      `/orders/${uid}/payment-status`
    );

    return data;
  }
}
