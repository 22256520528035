import qs from 'qs';

import { newBackendHttp } from '@/api/instances/rest';

import { IUniversalResponse } from '@/types/new/backend-responses';
import { IPromotion } from '@/types/new/promotions';

class PromotionsService {
  async getAll(): Promise<IUniversalResponse<{ items: IPromotion[]; total: number }>> {
    const query = qs.stringify({
      skip: 0,
      take: 100,
    });

    const { data } = await newBackendHttp.get<
      IUniversalResponse<{ items: IPromotion[]; total: number }>
    >(`/promotions?${query}`);

    return data;
  }

  async getById(id: number): Promise<IUniversalResponse<IPromotion>> {
    const { data } = await newBackendHttp.get<IUniversalResponse<IPromotion>>(`/promotions/${id}`);

    return data;
  }

  async getLastActive(): Promise<IUniversalResponse<{ items: IPromotion[]; total: number }>> {
    const query = qs.stringify({
      skip: 0,
      take: 1,
      orderBy: {
        created_at: 'desc',
      },
      where: {
        is_in_progress: true,
      },
    });

    const { data } = await newBackendHttp.get<
      IUniversalResponse<{ items: IPromotion[]; total: number }>
    >(`/promotions?${query}`);

    return data;
  }
}

export const promotionService = new PromotionsService();
