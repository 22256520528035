import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IAddressSuggestionItem } from '@/types/new/address';

export type BasketFormValuesType = {
  address: IAddressSuggestionItem | null;
  time: { value: string; label: string } | null;
  date: Date | null;
  flat: string;
  recipientName: string;
  recipientPhone: string;
  customerName: string;
  customerPhone: string;
  comment: string;
  promocode: string;
  delivery_tariff: 'default' | 'express' | 'just-in-time';
  dontTellCheckbox: boolean;
  postcard: string;
  bonusCount: number;
  emailForReceipt: string;
};

export type DeliveryFormType = null | {
  express: { deliveryId: number; distance: number; duration: number; price: number };
  justInTime: { deliveryId: number; distance: number; duration: number; price: number };
  default: { deliveryId: number; distance: number; duration: number; price: number };
};

type BasketFormState = {
  delivery: DeliveryFormType;
  formStep: number;
  formValues: BasketFormValuesType;
  isBuyItMyself: boolean;
  isPickup: boolean;
  withPostcard: boolean;
  deductPoints: boolean;
  deductPointsCount: number;
  workload: number;
  completedSteps: number[];
  actualDeliveryIntervals: { value: string; label: string }[];
  isDeliveryAvailable: {
    express: boolean;
    justInTime: boolean;
  };
};

const initialState: BasketFormState = {
  delivery: null,
  formValues: {
    dontTellCheckbox: false,
    address: null,
    time: null,
    date: null,
    flat: '',
    recipientName: '',
    recipientPhone: '',
    customerName: '',
    customerPhone: '',
    comment: '',
    promocode: '',
    delivery_tariff: 'default',
    postcard: '',
    bonusCount: 0,
    emailForReceipt: '',
  },
  formStep: 1,
  workload: 1,
  isBuyItMyself: false,
  isPickup: false,
  withPostcard: false,
  deductPoints: false,
  deductPointsCount: 0,
  completedSteps: [1],
  actualDeliveryIntervals: [],
  isDeliveryAvailable: {
    express: true,
    justInTime: true,
  },
};

export const basketFormSlice = createSlice({
  name: 'basketForm',
  initialState,
  reducers: {
    setDelivery(state, action: PayloadAction<DeliveryFormType | null>) {
      if (action.payload === null) {
        state.delivery = null;
      }
      state.delivery = action.payload;
    },
    setFormData(state, action: PayloadAction<Partial<BasketFormValuesType>>) {
      state.formValues = { ...state.formValues, ...action.payload };
    },
    changeFormStep(state, action: PayloadAction<number>) {
      state.formStep = action.payload;
    },
    toggleBuyItMyself(state) {
      state.isBuyItMyself = !state.isBuyItMyself;
    },
    togglePickup(state) {
      state.isPickup = !state.isPickup;
      state.completedSteps = [0, 1, 2];
    },
    toggleWithPostcard(state) {
      state.withPostcard = !state.withPostcard;
    },
    changeDeductStatus(state) {
      state.deductPoints = !state.deductPoints;
    },
    changeDeductCount(state, action: PayloadAction<number>) {
      state.deductPointsCount = action.payload;
    },
    setWorkload(state, action: PayloadAction<number>) {
      state.workload = action.payload;
    },
    setActualDeliveryIntervals(state, action: PayloadAction<{ value: string; label: string }[]>) {
      state.actualDeliveryIntervals = action.payload;
    },
    addStepToCompleted(state, action: PayloadAction<number>) {
      const isStepIncludes = state.completedSteps.includes(action.payload);

      if (isStepIncludes) return;

      state.completedSteps.push(action.payload);
    },
    setDeliveryAvailable(
      state,
      action: PayloadAction<{ express?: boolean; justInTime?: boolean }>
    ) {
      state.isDeliveryAvailable = { ...state.isDeliveryAvailable, ...action.payload };
    },
    resetTimeAndDate(state) {
      state.formValues.time = null;
      state.formValues.date = null;
    },
    resetForm: () => initialState,
  },
});

export const {
  setFormData,
  changeFormStep,
  toggleBuyItMyself,
  togglePickup,
  toggleWithPostcard,
  setDelivery,
  changeDeductStatus,
  changeDeductCount,
  setWorkload,
  addStepToCompleted,
  setActualDeliveryIntervals,
  setDeliveryAvailable,
  resetTimeAndDate,
  resetForm,
} = basketFormSlice.actions;
export default basketFormSlice;
