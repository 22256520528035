import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isOpen: boolean;
}

const initialState: InitialState = {
  isOpen: false,
};

export const paymentInformationModal = createSlice({
  name: 'paymentInformationModal',
  initialState,
  reducers: {
    toggleModal(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleModal } = paymentInformationModal.actions;
export default paymentInformationModal;
