import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isOpen: boolean;
}

const initialState: InitialState = {
  isOpen: false,
};

export const flowerInstructionModal = createSlice({
  name: 'flowerInstructionModal',
  initialState,
  reducers: {
    toggleModal(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleModal } = flowerInstructionModal.actions;
export default flowerInstructionModal;
