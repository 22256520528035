import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IProductCheckoutNew, IProductNew } from '@/types/new/products';

import { LS_CHECKOUT_PRODUCTS } from '@/constants/local-storage.constant';

import { fetchAdditionalProducts } from './checkout-product.thunks';

interface CheckoutProductState {
  products: IProductCheckoutNew[];
  sum: number;
  total: number;
  sale: number;
  salePercent: number;
  additionalProducts: {
    modalIsOpen: boolean;
    modalIsShowed: boolean;
    products: IProductNew[];
  };
}

const initialState: CheckoutProductState = {
  products: [],
  sum: 0,
  total: 0,
  sale: 0,
  salePercent: 0,
  additionalProducts: {
    modalIsOpen: false,
    modalIsShowed: false,
    products: [],
  },
};

type ChangeProductProps = {
  id: number;
  amount: number;
};

export const checkoutProductsSlice = createSlice({
  name: 'checkoutProducts',
  initialState,
  reducers: {
    totalCount(state) {
      let total = 0;
      state.products.forEach((product: IProductCheckoutNew) => (total += product.amount));
      state.total = total;
    },
    sumCount(state) {
      state.sum = state.products.reduce((acc, item) => {
        const price: number = item.product.price;
        const amount: number = item.amount;
        const promocodeIsAvailable: boolean = item.product.promocode_available;
        const priceAfterPromocode: number = promocodeIsAvailable
          ? price - price * (state.salePercent / 100)
          : price;

        return acc + Math.round(priceAfterPromocode) * amount;
      }, 0);

      state.sale = state.products.reduce((acc, item) => {
        const price: number = item.product.price;
        const amount: number = item.amount;
        const promocodeIsAvailable: boolean = item.product.promocode_available;
        const priceAfterPromocode: number = promocodeIsAvailable
          ? price * (state.salePercent / 100)
          : 0;

        return acc + priceAfterPromocode * amount;
      }, 0);
    },
    resetSale(state) {
      state.sale = initialState.sale;
      state.salePercent = initialState.salePercent;
    },

    addProduct(state, action: PayloadAction<IProductCheckoutNew>) {
      state.products.push(action.payload);
    },
    changeProduct(state, action: PayloadAction<ChangeProductProps>) {
      const { id, amount } = action.payload;
      const storageProduct = state.products.find((el: IProductCheckoutNew) => el.product.id === id);
      const storageProductIndex = state.products.findIndex(
        (el: IProductCheckoutNew) => el?.product.id === id
      );
      storageProduct?.product ? (storageProduct.amount = amount) : null;
      // @ts-ignore
      state.products.splice(storageProductIndex, 1, storageProduct);
    },
    updateProductsAttributes(state, action: PayloadAction<IProductNew[]>) {
      state.products = action.payload.map((newProd) => {
        const oldProd = state.products.find((prod) => prod.product.id === newProd.id);

        return {
          amount: oldProd ? oldProd.amount : 1,
          product: newProd,
        };
      });
    },
    removeProduct(state, action: PayloadAction<number>) {
      state.products = state.products.filter((product) => product.product.id !== action.payload);
    },
    countSale(state, action: PayloadAction<number>) {
      state.salePercent = action.payload;

      let sale: number = 0;

      state.products.forEach((el) => {
        if (el.product.promocode_available) {
          sale += el.product.price * (action.payload / 100) * el.amount;
        } else {
          sale += 0;
        }
      });

      state.sale = sale;
    },
    countFixedSale(state, action: PayloadAction<number>) {
      state.sale = action.payload;
    },
    removeFormProducts(state) {
      state.products = [];
      state.sum = 0;
      state.total = 0;
      state.sale = 0;
      state.salePercent = 0;
      localStorage.removeItem(`persist:${LS_CHECKOUT_PRODUCTS}`);
    },
    openModalWithAdditionalProducts(state) {
      state.additionalProducts = {
        ...state.additionalProducts,
        modalIsShowed: true,
        modalIsOpen: true,
      };
    },
    closeModalWithAdditionalProducts(state) {
      state.additionalProducts = {
        ...state.additionalProducts,
        modalIsOpen: false,
      };
    },
    resetModalWithAdditionalProductsShows(state) {
      state.additionalProducts = {
        ...state.additionalProducts,
        modalIsShowed: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdditionalProducts.fulfilled, (state, action) => {
      state.additionalProducts = {
        ...state.additionalProducts,
        products: action.payload.items,
      };
    });
  },
});

export const {
  addProduct,
  changeProduct,
  totalCount,
  sumCount,
  resetSale,
  removeProduct,
  countSale,
  removeFormProducts,
  updateProductsAttributes,
  countFixedSale,
  openModalWithAdditionalProducts,
  closeModalWithAdditionalProducts,
  resetModalWithAdditionalProductsShows,
} = checkoutProductsSlice.actions;
export default checkoutProductsSlice;
