import { newBackendHttp } from '@/api/instances/rest';

import { TGetHeaderMenu } from '@/types/new/header-menu';

export class HeaderMenuService {
  static async getMenu(): Promise<TGetHeaderMenu> {
    const { data } = await newBackendHttp.get<TGetHeaderMenu>('/site/header_menu');

    return data;
  }
}
