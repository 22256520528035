import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SupportInitialState {
  isOpen: boolean;
}

const initialState: SupportInitialState = {
  isOpen: false,
};

export const supportModal = createSlice({
  name: 'supportModal',
  initialState,
  reducers: {
    changeModalVisibility(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { changeModalVisibility } = supportModal.actions;
export default supportModal;
