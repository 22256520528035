import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LS_USER } from '@/constants/local-storage.constant';

type checkoutFormState = {
  userName: string | null;
  userId: number | null;
  currentBonusCount: number | null;
  login: boolean;
  loginPage: number | null;
  haveError: boolean;
  inviteeId: null | string;
  cashback: number;
};

const initialState: checkoutFormState = {
  userName: null,
  userId: null,
  currentBonusCount: null,
  login: false,
  loginPage: null,
  haveError: false,
  inviteeId: null,
  cashback: 0,
};

export const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState,
  reducers: {
    setUser(
      state,
      action: PayloadAction<{
        userId?: number;
        userName?: string;
        cashback?: number;
        bonusCount?: number;
      }>
    ) {
      state.userId = action.payload.userId ?? state.userId;
      state.userName = action.payload.userName ?? state.userName;
      state.cashback = action.payload.cashback ?? state.cashback;
      state.currentBonusCount = action.payload.bonusCount || state.currentBonusCount;
    },
    logoutUser(state) {
      state.userId = null;
      state.userName = null;
      state.login = false;
      state.cashback = 0;
      state.currentBonusCount = null;
    },
    loginUser(state) {
      state.login = true;
      state.loginPage = null;
    },
    changeLoginPage(state, action: PayloadAction<number | null>) {
      state.loginPage = action.payload;
    },
    getUser(state) {
      const user = localStorage.getItem(`persist:${LS_USER}`);
      if (user) state.userId = JSON.parse(user);
    },
    changeHaveError(state, action: PayloadAction<boolean>) {
      state.haveError = action.payload;
    },

    changeInviteeId(state, action: PayloadAction<string | null>) {
      state.inviteeId = action.payload;
    },
    changeCashback(state, action: PayloadAction<number>) {
      state.cashback = action.payload;
    },
  },
});

export const {
  setUser,
  logoutUser,
  loginUser,
  getUser,
  changeLoginPage,
  changeHaveError,
  changeInviteeId,
  changeCashback,
} = loginUserSlice.actions;
export default loginUserSlice;
