import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IDeliveryLimitsModal {
  type: 'common' | 'checkout';
  text: string;
}

interface InitialState {
  modal: null | IDeliveryLimitsModal;
  wasClosed: boolean;
}

const initialState: InitialState = {
  modal: null,
  wasClosed: false,
};

export const deliveryLimitsModal = createSlice({
  name: 'deliveryLimitsModal',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<IDeliveryLimitsModal>) {
      state.modal = action.payload;
      state.wasClosed = false;
    },
    closeModal(state) {
      state.modal = null;
      state.wasClosed = true;
    },
    setWasClosed(state, action: PayloadAction<boolean>) {
      state.wasClosed = action.payload;
    },
  },
});

export const { openModal, closeModal, setWasClosed } = deliveryLimitsModal.actions;
export default deliveryLimitsModal;
