import { createSlice } from '@reduxjs/toolkit';

interface MobileAppAdvertInitialState {
  isOpen: boolean;
}

const initialState: MobileAppAdvertInitialState = {
  isOpen: false,
};

export const mobileAppAdvertModal = createSlice({
  name: 'mobileAppAdvertModal',
  initialState,
  reducers: {
    showMobileAppAdvertModal(state, action) {
      state.isOpen = action.payload;
    },
    toggleMobileAppAdvertModal(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleMobileAppAdvertModal, showMobileAppAdvertModal } =
  mobileAppAdvertModal.actions;
export default mobileAppAdvertModal;
