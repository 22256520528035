import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { HeaderBannersService } from '@/services/header-banners.service';

import { IHeaderBanner } from '@/types/new/header-banners';

export const headerBannersApi = createApi({
  reducerPath: 'headerBannersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
  }),
  endpoints: (build) => ({
    getHeaderBanners: build.query<{ items: IHeaderBanner[]; total: number }, void>({
      queryFn: async () => {
        const { data } = await HeaderBannersService.getAllBanners();

        return { data };
      },
    }),
  }),
});

export const { useGetHeaderBannersQuery } = headerBannersApi;
