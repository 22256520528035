import { createSlice } from '@reduxjs/toolkit';

interface MainInfoModalInitial {
  modalIsOpen: boolean;
}

const initialState: MainInfoModalInitial = {
  modalIsOpen: false,
};

export const mainPageInfoModal = createSlice({
  name: 'mainPageInfoModal',
  initialState,
  reducers: {
    toggleMainPageInfoModal(state) {
      state.modalIsOpen = !state.modalIsOpen;
    },
  },
});

export const { toggleMainPageInfoModal } = mainPageInfoModal.actions;
export default mainPageInfoModal;
