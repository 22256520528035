import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LS_CURRENT_SELECTED_CITY_ID } from '@/constants/local-storage.constant';

interface CityInitialState {
  city: string;
  cityRegion: string;
  cityPickupPoint: string;
  timezoneUTC: number;
  default_delivery_intervals: { label: string; value: string }[];
  express_delivery_intervals: { label: string; value: string }[];
  precise_time_delivery_intervals: { label: string; value: string }[];
  pickup_latitude: number;
  pickup_longitude: number;
  max_delivery_area: number;
}

const initialState: CityInitialState = {
  city: '',
  cityRegion: '',
  cityPickupPoint: '',
  timezoneUTC: 0,
  default_delivery_intervals: [],
  express_delivery_intervals: [],
  precise_time_delivery_intervals: [],
  pickup_latitude: 0,
  pickup_longitude: 0,
  max_delivery_area: 0,
};

export const city = createSlice({
  name: 'city',
  initialState,
  reducers: {
    changeCity(
      state,
      action: PayloadAction<{
        cityId: number;
        name: string;
        cityRegion: string;
        cityPickupPoint: string;
        timezoneUTC: number;
        default_delivery_intervals: { label: string; value: string }[];
        express_delivery_intervals: { label: string; value: string }[];
        precise_time_delivery_intervals: { label: string; value: string }[];
        pickup_latitude: number;
        pickup_longitude: number;
        max_delivery_area: number;
      }>
    ) {
      const {
        cityId,
        default_delivery_intervals,
        express_delivery_intervals,
        precise_time_delivery_intervals,
        name,
        cityRegion,
        cityPickupPoint,
        timezoneUTC,
        pickup_longitude,
        pickup_latitude,
        max_delivery_area,
      } = action.payload;

      state.city = name;
      state.cityRegion = cityRegion;
      state.cityPickupPoint = cityPickupPoint;
      state.timezoneUTC = timezoneUTC;
      state.default_delivery_intervals = default_delivery_intervals;
      state.express_delivery_intervals = express_delivery_intervals;
      state.precise_time_delivery_intervals = precise_time_delivery_intervals;
      state.pickup_latitude = pickup_latitude;
      state.pickup_longitude = pickup_longitude;
      state.max_delivery_area = max_delivery_area;
      localStorage.setItem(LS_CURRENT_SELECTED_CITY_ID, String(cityId));
    },
  },
});

export const { changeCity } = city.actions;
export default city;
