import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

export const individualOrderModal = createSlice({
  name: 'timeout-popup',
  initialState,
  reducers: {
    switchModal(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { switchModal } = individualOrderModal.actions;
export default individualOrderModal;
