import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isOpen: boolean;
  price: number;
  variant: 'dolyame' | 'podeli' | 'split';
}

const initialState: InitialState = {
  isOpen: false,
  price: 0,
  variant: 'dolyame',
};

export const partsPaymentModal = createSlice({
  name: 'partsPaymentModal',
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<Omit<InitialState, 'isOpen'>>) {
      state.isOpen = true;
      state.price = action.payload.price;
      state.variant = action.payload.variant;
    },
    closeModal: () => initialState,
  },
});

export const { showModal, closeModal } = partsPaymentModal.actions;
export default partsPaymentModal;
