import qs from 'qs';

import { newBackendHttp } from '@/api/instances/rest';

import { IUniversalResponse } from '@/types/new/backend-responses';
import { IInstagramReview, IYandexReviewNew } from '@/types/new/reviews';

type TGetYandexReviews = IUniversalResponse<{ total: number; items: IYandexReviewNew[] }>;

type TOrder = 'asc' | 'desc';

type TYandexReviewSort =
  | 'name'
  | 'role'
  | 'stars_rating'
  | 'created_at'
  | 'updated_at'
  | 'created_by_id'
  | 'updated_by_id';

type TInstagramReviewSort = 'id' | 'created_at' | 'nickname' | 'rank';

export class ReviewService {
  static async getInstagramReviews(
    ids?: number[],
    skip = 0,
    take = 12,
    sort: TInstagramReviewSort = 'id',
    order: TOrder = 'desc'
  ): Promise<IUniversalResponse<IInstagramReview[]>> {
    const searchParams = qs.stringify(
      {
        skip,
        take,
        orderBy: { [sort]: order },
        ...(ids ? { where: { id: { in: ids } } } : {}),
      },
      { encodeValuesOnly: true, arrayFormat: 'repeat' }
    );

    const { data } = await newBackendHttp.get<IUniversalResponse<IInstagramReview[]>>(
      `/review?${searchParams}`
    );

    return data;
  }

  static async getYandexReviews(
    skip = 0,
    take = 12,
    sort: TYandexReviewSort = 'updated_at',
    order: TOrder = 'desc'
  ): Promise<TGetYandexReviews> {
    const searchParams = qs.stringify(
      { skip, take, orderBy: { [sort]: order } },
      { encodeValuesOnly: true }
    );

    const { data } = await newBackendHttp.get<TGetYandexReviews>(
      `/yandex-reviews/?${searchParams}`
    );

    return data;
  }
}
