import router from 'next/router';
import { useEffect } from 'react';

import { analyticMetric } from '@/helpers/analytics-metric';

export const useMetrikaPageHits = () => {
  const handleRouteChange = (url: string) => {
    analyticMetric.hitPage(url);
  };

  useEffect(() => {
    analyticMetric.hitPage(router.asPath);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);
};
