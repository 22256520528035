import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IProductNew } from '@/types/new/products';

interface OneClickOrderInitialState {
  modalIsOpen: boolean;
  selectedProduct: IProductNew | null;
}

const initialState: OneClickOrderInitialState = {
  modalIsOpen: false,
  selectedProduct: null,
};

export const oneClickOrder = createSlice({
  name: 'oneClickOrder',
  initialState,
  reducers: {
    toggleModal(state) {
      state.modalIsOpen = !state.modalIsOpen;
    },
    setSelectedProduct(state, action: PayloadAction<IProductNew | null>) {
      state.selectedProduct = action.payload;
    },
  },
});

export const { toggleModal, setSelectedProduct } = oneClickOrder.actions;
export default oneClickOrder;
